import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '*',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/berita',
    name: 'Berita',
    component: () => import('../views/Berita.vue')
  },
  {
    path: '/agenda',
    name: 'Agenda',
    component: () => import('../views/Agenda.vue')
  },
  {
    path: '/kegiatan',
    name: 'Kegiatan',
    component: () => import('../views/Kegiatan.vue')
  },
  // {
  //   path: '/tags',
  //   name: 'Tags',
  //   component: () => import('../views/Tags.vue')
  // },
  {
    path: '/berita/:slug',
    name: 'DetailBerita',
    component: () => import('../views/DetailBerita.vue')
  },
  {
    path: '/kegiatan/:slug',
    name: 'DetailKegiatan',
    component: () => import('../views/DetailKegiatan.vue')
  },
  {
    path: '/agenda/:slug',
    name: 'DetailAgenda',
    component: () => import('../views/DetailAgenda.vue')
  },
  // {
  //   path: '/tags/:slug',
  //   name: 'DetailTags',
  //   component: () => import('../views/DetailTags.vue')
  // },
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior (to, from, savedPosition){
    return new Promise((resolve) => {
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition)
        } else {
          resolve({ x:0,y:0});
        }
      }, 100);
    });
  },
  linkActiveClass: 'active',
  base: process.env.BASE_URL,
  routes
})
export default router
