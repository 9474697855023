<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "Perjuangan Guntur",
  },
};
</script>
<style lang="scss">
@import "@/scss/_variable.scss";
html,
body {
  scroll-behavior: smooth;
}
#app {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &.header {
      position: relative;
      display: inline-block;
      &::after {
        content: "";
        position: absolute;
        bottom: -14px;
        left: 0;
        width: 50%;
        border-radius: 50px;
        height: 4px;
        background-color: $primaryColor;
      }
    }
    &.header-center {
      position: relative;
      display: inline-block;
      &::after {
        content: "";
        position: absolute;
        bottom: -14px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 50%;
        border-radius: 50px;
        height: 4px;
        background-color: $primaryColor;
      }
    }
    font-family: "Maven Pro", Helvetica, Arial, sans-serif;
    font-weight: bold;
    color: black;
  }
  font-family: "Nunito", Helvetica, Arial, sans-serif;
  font-weight: 300;
  // height: 20000px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // color: #2c3e50;
}
</style>
